/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import queryString from 'query-string';
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import TableViewPopup from '../TableViewPopup/TableViewPopup';
import styles from './ActionButtons.module.scss';
import useToggle from '../../hooks/useToggle';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import { getCookies } from '../../hooks/useCookies';
import {
  ERNIE_APP_WEB_API_LOGIN,
  ERNIE_WEB_ADMIN_LOGIN,
  ERNIE_APP_WEB_API_ADMIN_LOGIN,
  ERNIE_APP_WEB_API_HIDE_POST,
  ERNIE_APP_WEB_API_DELETE_ZONE_NOTI,
  ERNIE_APP_WEB_API_ZONE_BLOCK_MAIL,
} from '../../redux/apiConstants';

function ActionButtons({ data, section, viewPopUp, deleteCondition, pageNum }) {
  const { actionbtnStyle, setPageNumView } = useContext(TableContext);
  const [actionPermissions, setActionPermission] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setDeletemessage] = useState();
  const [canDelete, setCanDelete] = useState(true);
  let deleteMessage;
  const { setDeletedData } = useContext(TableContext);
  const { setSpinLoader } = useContext(TableContext);
  const [showTablePopUp, setShowTablePopUp] = useToggle();
  const actionView = (param) => () => {
    let encodeText = param.id;
    if (section === 'activitylog') {
      encodeText = param._id;
    }
    if (data.context_id) {
      setPageNumView(pageNum);
      navigate(`/${section}/viewdetails/${encodeText}`);
    }
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      setPageNumView(pageNum);
      navigate(`/${section}/viewdetails/${encodeText}`);
    }
  };

  const actionLoginAsUser = (params) => {
    setSpinLoader(true);
    const userData = {
      email: params.owner_email,
      password: params.owner_password,
    };
    axios.post(ERNIE_APP_WEB_API_LOGIN, userData).then((res) => {
      const passingData = {
        token: JSON.stringify(res.data.data.response.token),
        user: JSON.stringify(res.data.data.response.user),
      };
      setSpinLoader(false);
      const passingDataParsed = queryString.stringify(passingData);
      const url = `${ERNIE_WEB_ADMIN_LOGIN}?${passingDataParsed}`;
      window.open(url, '_blank');
    });
  };

  const actionAdminAsUser = (params) => {
    setSpinLoader(true);
    axios.get(ERNIE_APP_WEB_API_ADMIN_LOGIN).then((res) => {
      const passingData = {
        token: JSON.stringify(res.data.data.response.token),
        user: JSON.stringify(res.data.data.response.user),
        // eslint-disable-next-line no-underscore-dangle
        zoneId: params.zone_id._id,
      };
      setSpinLoader(false);
      const passingDataParsed = queryString.stringify(passingData);
      const url = `${ERNIE_WEB_ADMIN_LOGIN}?${passingDataParsed}`;
      window.open(url, '_blank');
    });
  };

  const actionHide = (params) => {
    confirmAlert({
      title: canDelete ? '' : 'Oops!!',
      message: 'Are you sure want to Hide the post',
      buttons: canDelete
        ? [
            {
              label: 'Yes',
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `/reportedposts/report-post/${params.id}`;
                actionData.actionMethod = 'patch';
                dispatch(Entry(actionData)).then((responceData) => {
                  const dataPost = {
                    postcard: responceData.payload.data.reportedItem.context_id._id,
                  };
                  axios.post(ERNIE_APP_WEB_API_HIDE_POST, dataPost).then();
                  toast.success('Post hidden successfully');
                  setDeletedData(`data last hide is ${params.id}`);
                });
              },
            },
            {
              label: 'No',
            },
          ]
        : [
            {
              label: 'Ok',
            },
          ],
    });
  };
  const blockZone = (params) => {
    confirmAlert({
      title: canDelete ? '' : 'Oops!!',
      message: 'Are you sure want to block the zone',
      buttons: canDelete
        ? [
            {
              label: 'Yes',
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `reportedzones/report-zone/${params.id}`;
                actionData.actionMethod = 'patch';
                dispatch(Entry(actionData)).then(() => {
                  const apiData = {
                    repotItemId: params.id,
                  };
                  axios.post(ERNIE_APP_WEB_API_ZONE_BLOCK_MAIL, apiData).then((resp) => {});
                  toast.success('Zone blocked successfully');
                  setDeletedData(`data last hide is ${params.id}`);
                });
              },
            },
            {
              label: 'No',
            },
          ]
        : [
            {
              label: 'Ok',
            },
          ],
    });
  };
  const actionEdit = (params) => {
    const encodeText = params.id;
    navigate(`/${section}/edit/${encodeText}`);
  };
  const handleDelete = (params) => {
    if (section === 'mailtemplates') {
      deleteMessage = `Are you sure, you want to delete ${data.template_name} ?`;
    } else if (section === 'ernietv') {
      deleteMessage = `Are you sure, you want to delete this video?`;
    } else {
      deleteMessage = `Are you sure, you want to delete ${data.name} ?`;
    }
    confirmAlert({
      title: canDelete ? '' : 'Oops!!',
      message: deleteMessage,
      buttons: canDelete
        ? [
            {
              label: 'Yes',
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `${section}/${params.id}`;
                actionData.actionMethod = 'delete';
                dispatch(Entry(actionData)).then(() => {
                  if (section === 'ernieofficial' || section === 'fanzone') {
                    const apiData = {
                      zoneId: params.id,
                    };
                    axios.post(ERNIE_APP_WEB_API_DELETE_ZONE_NOTI, apiData).then((resp) => {});
                  }
                  if (section === 'cms') toast.success('CMS deleted successfully');
                  else if (section === 'mailtemplates')
                    toast.success('Template deleted successfully');
                  else if (section === 'ernietv') toast.success('Video deleted successfully');
                  else toast.success('Zone deleted successfully');
                  setDeletedData(`data last deleted is ${params.id}`);
                });
              },
            },
            {
              label: 'No',
            },
          ]
        : [
            {
              label: 'Ok',
            },
          ],
    });
  };
  useEffect(() => {
    const temp = section.replace(/\s+/g, '').toLowerCase();
    const permissions = getCookies('USERPERMISSION');
    let matchFound = false;

    for (let i = 0; i < permissions.length; i += 1) {
      const val = permissions[i];

      if (val.section.toLowerCase() === temp) {
        setActionPermission({
          view: val.view || false,
          edit: val.edit || false,
          create: val.create || false,
          delete: val.delete || false,
          list: !!(val.view || val.edit || val.create || val.delete),
        });

        matchFound = true;
        break;
      } else if (val.submenu) {
        for (let j = 0; j < val.submenu.length; j += 1) {
          const element = val.submenu[j];

          if (element.section.toLowerCase() === temp) {
            setActionPermission({
              view: element.view || false,
              edit: element.edit || false,
              create: element.create || false,
              delete: element.delete || false,
              list: !!(element.view || element.edit || element.create || element.delete),
            });

            matchFound = true;
            break;
          }
        }

        if (matchFound) {
          break;
        }
      }
    }
    if (deleteCondition.checkCondition) {
      setCanDelete(false);
      setDeletemessage('Sorry you cant delete this data');
    }
  }, []);
  return (
    <>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" id={styles.btnflex}>
        {actionPermissions.view && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.detail_actionbtn}
            type="button"
            onClick={actionView(data)}
          >
            {' '}
            Details
          </button>
        )}
        {actionPermissions.edit && section !== 'reportedposts' && section !== 'reportedzones' && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.detail_actionbtn}
            type="button"
            onClick={() => {
              actionEdit(data);
            }}
          >
            {' '}
            Edit
          </button>
        )}
        {actionPermissions.delete && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.action_btn_warning}
            type="button"
            onClick={() => {
              handleDelete(data);
            }}
          >
            {' '}
            Delete
          </button>
        )}
        {section === 'reportedposts' && data.status === 1 && (
          <button id={styles.action_btn_warning} type="button" onClick={() => actionHide(data)}>
            Hide
          </button>
        )}
        {section === 'reportedzones' && data.status === 1 && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.action_btn_warning}
            type="button"
            onClick={() => blockZone(data)}
          >
            Block
          </button>
        )}

        {section === 'ernieofficial' && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.detail_actionbtn}
            type="button"
            onClick={() => actionLoginAsUser(data)}
          >
            {' '}
            View
          </button>
        )}

        {section === 'reportedzones' && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.detail_actionbtn}
            type="button"
            onClick={() => actionAdminAsUser(data)}
          >
            {' '}
            View
          </button>
        )}

        {/* {section === 'cms' && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.detail_actionbtn}
            type="button"
            onClick={() => {
              actionEdit(data);
            }}
          >
            {' '}
            Edit
          </button>
        )} */}
      </div>
      {showTablePopUp ? (
        <TableViewPopup dataId={data.id} handleClose={setShowTablePopUp} section={section} />
      ) : null}
    </>
  );
}

ActionButtons.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  viewPopUp: PropTypes.bool.isRequired,
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  pageNum: PropTypes.number.isRequired,
};
ActionButtons.defaultProps = {
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
};
export default ActionButtons;
