/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import envValues from '../../enviornment';
import './view.css';

function ViewTemplate() {
  const navigate = useNavigate();
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  let templateId;
  const params = useParams();
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/mailtemplates/${templateId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.data.template);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    templateId = params.templateId;
    getUserDetails();
    setDashboardHeader('Template Details');
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.empdetailssection}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          {loading ? (
            <SpinnerDotted
              style={{
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                color: '#fe0001',
              }}
            />
          ) : (
            <Container fluid>
              <div className="content">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card-box task-detail">
                        <ul className="list-inline task-dates m-b-0 mt-5">
                          <li>
                            <h5 className="m-b-5">Template Name</h5>
                            <p>{details?.template_name}</p>
                          </li>
                          <li>
                            <h5 className="m-b-5">Template Subject</h5>
                            <p>{details?.template_subject}</p>
                          </li>
                        </ul>
                        <h5 className="m-b-5">Template Preview</h5>
                        <div className="clearfix m-4" />

                        <div dangerouslySetInnerHTML={{ __html: details?.template_body }} />
                      </div>
                    </div>
                    {/* end col */}

                    {/* end col */}
                  </div>
                  {/* end row */}
                </div>
                {/* container */}
              </div>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewTemplate;
