/* eslint-disable global-require */
/* eslint-disable no-plusplus */
import axios from 'axios';
import React, { Suspense, useEffect, useState, useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { SpinnerDotted } from 'spinners-react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
// import YearPicker from 'react-year-picker';
import DatePicker from 'react-datepicker';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './viewErnietv.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import envValues from '../../enviornment';
import Loader from '../../components/Loader/Loader';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import ChartFunction from '../../components/Chart/Linechart';

function ViewErnieTv() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle, graphbgStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let zoneId;
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(false);
  const [months, setMonths] = useState([]);
  const [graph, setGraph] = useState([]);
  const [details, setDetails] = useState([]);
  const [timeFrame, setTimeFrame] = useState('yearly');
  const [selectedDate, setSelectedDate] = useState(new Date());
  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    let date = `?zoneId=${zoneId}`;

    if (timeFrame === 'yearly') {
      const startDate = new Date(selectedDate.getFullYear(), 0, 1).toISOString();
      const endDate = new Date(selectedDate.getFullYear(), 11, 31).toISOString();
      date += `&startDate=${startDate}&endDate=${endDate}&type=${timeFrame}`;
    } else if (timeFrame === 'monthly') {
      const startDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      ).toISOString();
      const endDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      ).toISOString();
      date += `&startDate=${startDate}&endDate=${endDate}&type=${timeFrame}`;
    } else if (timeFrame === 'thisWeek') {
      const startOfWeek = new Date();
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

      date += `&startDate=${startOfWeek.toISOString()}&endDate=${new Date().toISOString()}&type=${timeFrame}`;
    } else if (timeFrame === 'prevWeek') {
      const startOfPrevWeek = new Date();
      startOfPrevWeek.setDate(startOfPrevWeek.getDate() - startOfPrevWeek.getDay() - 7);
      const endOfPrevWeek = new Date();
      endOfPrevWeek.setDate(endOfPrevWeek.getDate() - endOfPrevWeek.getDay() - 1);

      date += `&startDate=${startOfPrevWeek.toISOString()}&endDate=${endOfPrevWeek.toISOString()}&type=${timeFrame}`;
    }
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/ernietv/${zoneId}${date}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.ernieTv);
          const monthArray = [];
          const dataArray = [];

          if (timeFrame === 'yearly') {
            res?.data?.ernieTvViews.forEach((view) => {
              if (view.month) {
                monthArray.push(view.month);
                dataArray.push(view.count);
              }
            });
          } else if (timeFrame === 'thisWeek' || timeFrame === 'prevWeek') {
            res?.data?.ernieTvViews.forEach((view) => {
              monthArray.push(view.day);
              dataArray.push(view.count);
            });
          } else if (timeFrame === 'monthly') {
            res?.data?.ernieTvViews.forEach((view) => {
              monthArray.push(view.week);
              dataArray.push(view.count);
            });
          }
          setMonths(monthArray);
          setGraph(dataArray);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    zoneId = params.ernieTvId;
    getUserDetails();
    setDashboardHeader('Ernie TV Details');
  }, [selectedDate]);
  const handleTimeFrameChange = (e) => {
    setTimeFrame(e.target.value);
    if (e.target.value === 'thisWeek' || e.target.value === 'prevWeek') {
      setSelectedDate(new Date());
    }
  };
  const getDatePicker = () => {
    if (timeFrame === 'monthly') {
      return (
        <div className={styles.select_timeframe}>
          <span>Select Month: </span>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="MMMM"
            showMonthYearPicker
          />
        </div>
      );
    }
    if (timeFrame === 'yearly') {
      return (
        <div className={styles.select_timeframe}>
          <span>Select Year: </span>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showYearPicker
            dateFormat="yyyy"
          />
        </div>
      );
    }
    return null;
  };
  return (
    <div className={styles[bodyStyle]}>
      {loading ? (
        <Loader />
      ) : (
        <div
          className={styles[dashboardStyle]}
          style={{ height: '100vh' }}
          id={styles.dashboardcont2}
        >
          <div className={styles[formthemeStyle]} id={styles.addform}>
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row className="mt-3">
                <Col lg={6}>
                  <div className={`${styles.mt_30} ${styles[viewformStyle]}`} id={styles.empdiv}>
                    <span className={styles.title}>Video Title</span>
                    <p className={styles.empname}>{details.title}</p>
                  </div>

                  <Card className={styles[graphbgStyle]} id={styles.chartcards}>
                    <Suspense
                      fallback={
                        <SpinnerDotted
                          style={{
                            marginLeft: '100px',
                            color: '#fe0001',
                            marginTop: '100px',
                          }}
                        />
                      }
                    >
                      {months.length > 0 && graph.length > 0 ? (
                        <ChartFunction
                          labels={months}
                          title="User Views"
                          data={[
                            {
                              label: 'User Views',
                              data: graph,
                              borderColor: 'rgb(255, 99, 132)',
                              backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                          ]}
                        />
                      ) : (
                        <div className={styles.no_view}>
                          <svg
                            id="Capa_1"
                            enableBackground="new 0 0 426.667 426.667"
                            height="40"
                            viewBox="0 0 426.667 426.667"
                            width="40"
                          >
                            <g>
                              <g clipRule="evenodd" fillRule="evenodd" transform="translate(-174)">
                                <path d="m387.333 0c117.739 0 213.333 95.595 213.333 213.333s-95.595 213.333-213.333 213.333-213.333-95.594-213.333-213.333 95.595-213.333 213.333-213.333zm0 128c-30.464 0-58.709 12.661-81.557 28.117-33.291 22.528-54.901 50.72-54.901 50.72-2.944 3.829-2.944 9.163 0 12.992 0 0 21.611 28.192 54.901 50.72 22.848 15.456 51.093 28.117 81.557 28.117s58.709-12.661 81.557-28.117c33.291-22.528 54.901-50.72 54.901-50.72 2.944-3.829 2.944-9.163 0-12.992 0 0-21.611-28.192-54.901-50.72-22.847-15.456-51.093-28.117-81.557-28.117z" />
                                <path d="m501.435 213.333c-7.829-8.917-23.659-25.44-44.501-39.552-19.531-13.216-43.552-24.448-69.6-24.448s-50.069 11.232-69.6 24.448c-20.843 14.112-36.672 30.635-44.501 39.552 7.829 8.917 23.659 25.44 44.501 39.552 19.531 13.216 43.552 24.448 69.6 24.448s50.069-11.232 69.6-24.448c20.842-14.112 36.671-30.634 44.501-39.552zm-114.102-42.666c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.666 42.667 42.666 42.667-19.115 42.667-42.667-19.115-42.666-42.667-42.666z" />
                                <circle cx="387.333" cy="213.333" r="21.333" />
                              </g>
                            </g>
                          </svg>
                          <span>No User Views</span>
                        </div>
                      )}
                    </Suspense>
                  </Card>
                  <div className={styles.select_timeframe}>
                    <label htmlFor="timeFrame">TimeFrame: </label>
                    <select id="timeFrame" value={timeFrame} onChange={handleTimeFrameChange}>
                      <option value="thisWeek">This Week</option>
                      <option value="prevWeek">Previous Week</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                  </div>
                  {getDatePicker()}
                </Col>
                <Col lg={6} className={styles.ernietv_video}>
                  <VideoPlayer
                    autoplay={false}
                    thumbnail={details.video_thumbnail}
                    videoSrc={details.output_video}
                    label="Ernie TV"
                    fieldName="Ernie TV"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewErnieTv;
