import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_REPORTEDITEM = `${envValues.REACT_APP_API_ENDPOINT}/reportedposts?`;
export const REACT_APP_API_FETCH_REPORTEDPOST = `${envValues.REACT_APP_API_ENDPOINT}/reportedposts/zonePosts?`;
export const REACT_APP_API_FETCH_REPORTEDZONE = `${envValues.REACT_APP_API_ENDPOINT}/reportedzones?`;
export const REACT_APP_API_FETCH_CMS = `${envValues.REACT_APP_API_ENDPOINT}/cms?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_REPORTS = `${envValues.REACT_APP_API_ENDPOINT}/reports?`;
export const REACT_APP_API_ERNIE_OFFICIAL = `${envValues.REACT_APP_API_ENDPOINT}/ernieofficial?`;
export const REACT_APP_API_ERNIE_TV = `${envValues.REACT_APP_API_ENDPOINT}/ernietv?`;
export const REACT_APP_API_ERNIE_OFFICIAL_GET_CHANNELS = `${envValues.REACT_APP_API_ENDPOINT}/ernieofficial/get-channels?`;
export const REACT_APP_API_ERNIE_OFFICIAL_EXISTS = `${envValues.REACT_APP_API_ENDPOINT}/ernieofficial/checkErnieOfficial?`;
export const ERNIE_APP_WEB_API_LOGIN = `${envValues.ERNIE_API_ENDPOINT}/auth/login`;
export const ERNIE_APP_WEB_API_ADMIN_LOGIN = `${envValues.ERNIE_API_ENDPOINT}/auth/adminLogin`;
export const ERNIE_APP_WEB_API_CREATE_ZONE = `${envValues.ERNIE_API_ENDPOINT}/zone/createZoneFromAdmin`;
export const ERNIE_APP_WEB_API_ZONE_BLOCK_MAIL = `${envValues.ERNIE_API_ENDPOINT}/reportitem/sent-mail`;
export const ERNIE_APP_WEB_API_DELETE_ZONE_NOTI = `${envValues.ERNIE_API_ENDPOINT}/notifications/delete-zone-notifications`;
export const ERNIE_WEB_ADMIN_LOGIN = `${envValues.ERNIE_WEB_ADMIN_LOGIN}`;
export const ERNIE_APP_WEB_API_HIDE_POST = `${envValues.ERNIE_API_ENDPOINT}/postcards/hidepost`;
export const REACT_APP_API_FAN_ZONE = `${envValues.REACT_APP_API_ENDPOINT}/fanzone?`;
export const REACT_APP_API_FETCH_TEMPLATE = `${envValues.REACT_APP_API_ENDPOINT}/mailtemplates?`;
export const REACT_APP_API_FETCH_SIGNED_URL = `${envValues.REACT_APP_API_ENDPOINT}/aws/get-signedUrl`;
export const REACT_APP_API_ACTIVITYLOG = `${envValues.REACT_APP_API_ENDPOINT}/activitylog?`;
