import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Outerlayout from '../layouts/outerLayout';
import Innerlayout from '../layouts/innerLayout';
import { getCookies, removeCookies } from '../hooks/useCookies';
import Settings from '../pages/settings/settings';
import ViewUsers from '../pages/users/viewUser';
import UserEdit from '../pages/users/editUser';
import UserIndex from '../pages/users/index';
import UserCreate from '../pages/users/createUser';
import Preferences from '../pages/settings/preferences';
import CreateRoles from '../pages/roles/createRoles';
import RolesIndex from '../pages/roles/index';
import EditRoles from '../pages/roles/editRoles';
import SettingsApi from '../pages/settings/SettingsApiPage';
import SettingsLayout from '../pages/settings/settingsLayout';
import PageNotFound from '../pages/404/pageNotFound';
import PermissionDenied from '../pages/permission/permission';
import ProfileLayout from '../pages/profile/profileLayout';
import Loader from '../components/Loader/Loader';
import CmsCreate from '../pages/cms/create';
import ViewTemplate from '../pages/mail-templates/view';
import EditTemplate from '../pages/mail-templates/edit';
import TemplateCreate from '../pages/mail-templates/create';
import CreateErnieTv from '../pages/ernietv/createErnietv';
import EditErnieTv from '../pages/ernietv/editErnieTv';
import ViewErnieTv from '../pages/ernietv/viewErnietv';

const Login = lazy(() => import('../pages/logIn/logIn'));
const UserVerification = lazy(() => import('../pages/verify/userVerfication'));
const Register = lazy(() => import('../pages/register/register'));
const Forgetpassword = lazy(() => import('../pages/passwords/forgetPassword'));
const ResetPassword = lazy(() => import('../pages/passwords/resetPassword'));
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
const ProfilePage = lazy(() => import('../pages/profile/profilepage'));
const ChangePassword = lazy(() => import('../pages/profile/changePassword'));
const ProfileEdit = lazy(() => import('../pages/profile/profileEdits'));
const Faq = lazy(() => import('../cms/faq'));
const ViewReporteditems = lazy(() => import('../pages/reporteditems/view'));
const ViewReportedzones = lazy(() => import('../pages/reportedzones/view'));
const ViewRole = lazy(() => import('../pages/roles/view'));
const ReporteditemIndex = lazy(() => import('../pages/reporteditems/index'));
const ReportedpostIndex = lazy(() => import('../pages/reportedpostszone/index'));
const ReportedZoneIndex = lazy(() => import('../pages/reportedzones/index'));
const ErnieOfficialIndex = lazy(() => import('../pages/ernieofficial/index'));
const ErnieTvIndex = lazy(() => import('../pages/ernietv/index'));
const CmsIndex = lazy(() => import('../pages/cms/index'));
const TemplateIndex = lazy(() => import('../pages/mail-templates/index'));
const ViewCms = lazy(() => import('../pages/cms/view'));
const CmsEdit = lazy(() => import('../pages/cms/edit'));
const ReportsIndex = lazy(() => import('../pages/reports/index'));
const CreateErnieOfficial = lazy(() => import('../pages/ernieofficial/createErnieOfficial'));
const EditErnieOfficial = lazy(() => import('../pages/ernieofficial/editErnieOfficial'));
const ViewErnieOfficial = lazy(() => import('../pages/ernieofficial/viewErnieOfficial'));
const FanZoneIndex = lazy(() => import('../pages/fanzones/index'));
const ViewFanZone = lazy(() => import('../pages/fanzones/viewErnieOfficial'));
const ActivityLogIndex = lazy(() => import('../pages/activitylog/index'));
const ViewActivityLog = lazy(() => import('../pages/activitylog/viewActivityLog'));

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense fallback={<Loader />}>
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense fallback={<Loader />}>
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense fallback={<Loader />}>
                <UnProtectedRoute>
                  {' '}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <Suspense fallback={<Loader />}>
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense fallback={<Loader />}>
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense fallback={<Loader />}>
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset"
            element={
              <Suspense fallback={<Loader />}>
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/roles"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reportedposts"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ReporteditemIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reportedzoneposts"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ReportedpostIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reportedzones"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ReportedZoneIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reportedposts/viewdetails/:postId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewReporteditems />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reportedzones/viewdetails/:postId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewReportedzones />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernietv"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ErnieTvIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernietv/create"
            element={
              <Suspense fallback={<Loader />}>
                <CreateErnieTv>
                  {' '}
                  <Faq />
                </CreateErnieTv>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernietv/edit/:ernieTvId"
            element={
              <Suspense fallback={<Loader />}>
                <EditErnieTv>
                  {' '}
                  <Faq />
                </EditErnieTv>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernietv/viewdetails/:ernieTvId"
            element={
              <Suspense fallback={<Loader />}>
                <ViewErnieTv>
                  {' '}
                  <Faq />
                </ViewErnieTv>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernieofficial"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ErnieOfficialIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernieofficial/create"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <CreateErnieOfficial />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernieofficial/edit/:zoneId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <EditErnieOfficial />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/ernieofficial/viewdetails/:zoneId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewErnieOfficial />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/fanzone"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <FanZoneIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/fanzone/viewdetails/:zoneId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewFanZone />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <CmsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms/create"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <CmsCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms/viewdetails/:cmsId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewCms />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms/edit/:cmsId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <CmsEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/mailtemplates"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <TemplateIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/mailtemplates/create"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <TemplateCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/mailtemplates/viewdetails/:templateId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewTemplate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/mailtemplates/edit/:templateId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <EditTemplate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reports"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ReportsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/activitylog"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ActivityLogIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/activitylog/viewdetails/:activitylogId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewActivityLog />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/edit/:userId"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <UserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  {' '}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute>
                    {' '}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute>
                    {' '}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute>
                    {' '}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute>
                    {' '}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute>
                    {' '}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/cms"
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute>
                    {' '}
                    <Faq />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            <Route
              exact
              path="/changePassword"
              element={
                <Suspense fallback={<Loader />}>
                  <ProtectedRoute>
                    {' '}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              {' '}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute>
                {' '}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies('Token')) {
    if (getCookies('USERPERMISSION')) {
      return children;
    }
    removeCookies('Token')
      .then(() => removeCookies('refreshToken'))
      .then(() => {
        sessionStorage.setItem('cookiesCleared', true);
        return <Navigate to="/login" />;
      });
  }
  sessionStorage.setItem('cookiesCleared', true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies('Token') ? <Navigate to="/dashboard" /> : children;
}
